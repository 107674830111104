<template>
  <div class="label-container">
    <div id="label">
      {{ coordinate }} Zoomlevel: {{ zoom }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CoordinateComponent",
  props: {
    coordinate: String,
    zoom: Number
  }
}
</script>

<style scoped>
.label-container {
  position: absolute;
  bottom: 32px;
  left: 2px;
  z-index: 1;
}

.label-container > * {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 11px;
  line-height: 18px;
  display: block;
  margin: 0;
  padding: 5px 10px;
  border-radius: 3px;
}
</style>